.calogon_success_body{
    display: -webkit-box;
    -webkit-box-orient: horizontal;
    -webkit-box-pack: center;
    -webkit-box-align: center;
    text-align: center;
}

.calogon_success_context{
    width: 100%;
    background-color: #fff;
}

.calogon_success_title{
    font-size: 16px;
    color: #F08984;
    letter-spacing: 0.6px;
    background-color: #F5F1EE;
    font-weight: 700;
}
.calogon_success_message{
    font-size: 12px;
    color: #555;
    letter-spacing: 0.6px;
    line-height: 24px;
}
.calogon_success_subTitle{
    font-size: 18px;
    letter-spacing: 0.9px;
    font-weight: 600;
    color: #555;
    margin-bottom: 38px;
}
.calogon_success_message_context{
    background-color: #fff;

    :global(.ant-btn) {
        padding: 0;
    }

    display: flex;
    align-items: center; /* 垂直居中 */
    justify-content: center; /* 水平居中 */
    height: 100vh; /* 容器高度 */
}

.right_icon{
    position: absolute;
    right: 12px;
    transform: translateY(25%);
}
.left_icon{
    position: absolute;
    left: 12px;
    transform: translateY(25%);
}

.calogon_success_butn_login{

    :global(.ant-btn-default) {
        border-color: #F08984;
      }

      :global(.ant-btn:hover) {
          border-color: #F08984;
          box-shadow: none;
          color: #fff;
          background-color: #F08984;
      }

      :global(.ant-btn:focus) {
          border-color: #F08984;
          box-shadow: none;
          color: #fff;
          background-color: #F08984;
      }
}

.calogon_success_butn_home{
    :global(.ant-btn-default) {
        border-color: #F08984;
      }

      :global(.ant-btn:hover) {
          border-color: #F08984;
          box-shadow: none;
          color: #F08984;
          background-color: #fff;
      }

      :global(.ant-btn:focus) {
          border-color: #F08984;
          box-shadow: none;
          color: #F08984;
          background-color: #fff;
      }
}
